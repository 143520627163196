import React, {useEffect, useRef, useState} from "react";
import "./style.scss";
import 'react-calendar-timeline/lib/Timeline.css';
import moment from "moment-business-days";
import Timeline from 'react-calendar-timeline';
import CalendarHeaders from "./CalendarHeaders";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faSyncAlt,
    faTrash,
    faChevronLeft,
    faChevronRight,
    faSortUp,
    faSortDown,
    faPlus,
    faClone,
    faPen
} from '@fortawesome/free-solid-svg-icons'
import {itemRenderer} from "./Item/Index";
import ReactTooltip from "react-tooltip";
import ProjectGroup from "./Group/ProjectGroup";
import TeamGroup from "./Group/TeamGroup";
import {getAllUrlParams} from "../../helpers/http-helper";
import {query_api_delete, query_api_put} from "../../helpers/ajax";
import UserGroup from "./Group/UserGroup"
import ReactPaginate from 'react-paginate'
import {useHistory} from "react-router";
import Switch from "react-switch";
import DuplicateForecastModal from "../Modal/DuplicateForecastModal";
import {clockToMinutes} from "../../helpers/format-helper";
import {animErrorPost, animErrorForm} from "../../helpers/animation";
import {query_api_post} from "../../helpers/ajax";
import {validateDATE, validateTIME} from "../../helpers/data-validation";
import EditForecastModal from "../Modal/EditForecastModal";


const maxLineByPage = 30


const Calendar = (props) => {

    const mounted = useRef();
    const [openGroups, setOpenGroups] = useState({last: [], now: []})
    const [filter, setFilter] = useState({
        last: (typeof getAllUrlParams().filter === "undefined") ? "" : getAllUrlParams().filter.toLowerCase(),
        now: (typeof getAllUrlParams().filter === "undefined") ? "" : getAllUrlParams().filter.toLowerCase()
    })
    const [selectedItem, setSelectedItem] = useState(null)
    const [items, setItems] = useState([...props.items])
    const [headers, setHeaders] = useState(["month", "week", "day"])
    const [calendarTimeStart, setCalendarTimeStart] = useState((props.isTimesheet)? moment().subtract(9, 'day').valueOf() : moment().subtract(1, 'day').valueOf())
    const [calendarTimeEnd, setCalendarTimeEnd] = useState((props.isTimesheet)?  moment().add(1, 'day').valueOf() : moment().add(9, 'day').valueOf())
    const [sideBarWidth, setSideBarWidth] = useState((window.innerWidth < 600)? 150 : 250)
    const history = useHistory();

    const groupRenderer = (props.type === "projects")? ProjectGroup : (props.type === "users")? UserGroup : TeamGroup
    const maxZoom = (headers.includes("month"))? 1000 * 86400 * 60 : 60 * 60 * 1000 * 24 * 2
    const minZoom = (headers.includes("month"))? 60 * 60 * 1000 * 24 * 2 : 60 * 60 * 1000 * 6

    const [timelineNode, setTimelineNode] = useState(null)
    const [pageId, setPageId] = useState({last: 0, now: 0})

    const [rawGroups, setRawGroups] = useState(props.groups)
    const [groups, setGroups] = useState({visible: [], filtered: [], sliced: []})
    const pageCount = Math.ceil(groups.filtered.length / maxLineByPage)
    const [allowPagination, setAllowPagination] = useState(true)

    const [open, setOpen] = useState(false);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [timePerDay, setTimePerDay] = useState("01:00");
    const [errorMessage, setErrorMessage] = useState("");
    const [loading, setLoading] = useState(false);

    const [edit, setEdit] = useState(false);
    const [time, setTime] = useState("01:00");
    const [forecast, setForecast] = useState(false);

    useEffect((sideBarWidth) => {
        const timeout = setTimeout(loadTimelineNode, 3000)
        window.addEventListener("resize", () => {
            if(window.innerWidth < 600) setSideBarWidth(150)
            else if(sideBarWidth === 150) setSideBarWidth(250)
        });

        return ((sideBarWidth) => {
            clearTimeout(timeout)
            window.removeEventListener("resize", () => {
                if (window.innerWidth < 600) setSideBarWidth(150)
                else if (sideBarWidth === 150) setSideBarWidth(250)
            })
        })
    }, [])


    useEffect((items) => {
        if (!mounted.current) {
            // do componentDidMount logic
            mounted.current = true;
            //if(props.groups.length > 0) hydrateGroups(true)

        } else {
            // do componentDidUpdate logic
            if(props.items !== items)
                setItems(props.items)
        }
    });


    const hydrateGroups = () => {
        let slicedGroups, filteredGroups

        if(openGroups.last !== openGroups.now || props.groups !== rawGroups){
            const visibleGroups = generateVisibleGroups()
            filteredGroups = generateFilteredGroups(visibleGroups)
            slicedGroups = generateSlicedGroups(filteredGroups)

            if(props.groups !== rawGroups) setRawGroups(props.groups)
            setOpenGroups({last: openGroups.now, now: openGroups.now})
            setGroups({visible: visibleGroups, filtered: filteredGroups, sliced: slicedGroups})
        }
        else if(filter.last !== filter.now){
            filteredGroups = generateFilteredGroups(groups.visible)
            slicedGroups = generateSlicedGroups(filteredGroups)

            setFilter({last: filter.now, now: filter.now})
            setGroups({visible: groups.visible, filtered: filteredGroups, sliced: slicedGroups})
        }
        else if(pageId.last !== pageId.now && allowPagination){
            slicedGroups = generateSlicedGroups(groups.filtered)

            setPageId({last: pageId.now, now: pageId.now})
            setGroups({visible: groups.visible, filtered: groups.filtered, sliced: slicedGroups})
        }
        else {
            filteredGroups = groups.filtered
            slicedGroups = groups.sliced
        }

        if(!allowPagination) return (filteredGroups.length === 0)? [{}] : filteredGroups
        return (slicedGroups.length === 0)? [{}] : slicedGroups
    }


    const loadTimelineNode = () => {
        const node = document.getElementById("timeline")
        if(typeof node !== "undefined") setTimelineNode(node)
        else setTimeout(() => setTimelineNode(document.getElementById("timeline")), 5000)
    }


    const handleFilterChange = (e) => {
        setFilter({last: filter.now, now: e.target.value.toLowerCase()})
        onPageChange(0)
    }


    const handleHeaderClick = (unit) => {
        if(unit === "week") setHeaders(["month", "week", "day"])
        else if(unit === "day") setHeaders(["week", "day", "hour"])
    }


    const resetTimeline = () =>{
        setHeaders(["month", "week", "day"])
        setCalendarTimeStart((props.isTimesheet)? moment().subtract(9, 'day').valueOf() : moment().subtract(1, 'day').valueOf())
        setCalendarTimeEnd((props.isTimesheet)?  moment().add(1, 'day').valueOf() : moment().add(9, 'day').valueOf())
    }


    const handleTimeChange = (_calendarTimeStart, _calendarTimeEnd, updateScrollCanvas) => {
        setCalendarTimeStart(_calendarTimeStart)
        setCalendarTimeEnd(_calendarTimeEnd)
    }


    const onItemMove = (itemId, dragTime) => {
        const _items = [...items], backup = items.map(a => ({...a}));
        const item = _items.find((item) => item.id === itemId);
        const new_start_time = moment(dragTime).startOf('day');
        const new_end_time = moment(item.end_time.valueOf() + (dragTime - item.start_time.startOf('day'))).subtract(1, 'day').endOf('day')

        if(new_start_time.isBusinessDay() || new_end_time.isBusinessDay()){

            _items[_items.indexOf(item)].end_time = new_end_time
            _items[_items.indexOf(item)].start_time = new_start_time

            item.obj.startDate = new_start_time.format('YYYY-MM-DD');
            item.obj.endDate = new_end_time.format('YYYY-MM-DD');

            const duration = moment(item.obj.endDate).endOf('day').businessDiff(moment(item.obj.startDate).startOf('day'), 'days');
            item.obj.dayDuration = (duration === 0)? 1 : duration;

            setItems(_items)

            query_api_put(item.obj['@id'], item.obj).catch((err) => {
                console.error(err);
                setItems(backup)

                if(err.response.status === 401)
                    window.location.reload();
            });
        }
    };


    const onItemResize = (itemId, time) => {
        const _items = [...items], backup = items.map(a => ({...a}));
        const updated_item_ref = items.find((item) => item.id === itemId);
        const new_end_time = moment(time).endOf('day')

        if(updated_item_ref.start_time.isBefore(new_end_time)){

            _items[_items.indexOf(updated_item_ref)].end_time = new_end_time
            updated_item_ref.obj.endDate = new_end_time.format('YYYY-MM-DD');

            const duration = moment(updated_item_ref.obj.endDate).endOf('day').businessDiff(moment(updated_item_ref.obj.startDate).startOf('day'), 'days');
            updated_item_ref.obj.dayDuration = (duration === 0)? 1 : duration;

            setItems(_items)

            query_api_put(updated_item_ref.obj['@id'], updated_item_ref.obj).catch((err) => {
                console.error(err);
                setItems(backup)

                if(err.response.status === 401)
                    window.location.reload();
            });
        }
    };

    function toHoursAndMinutes(totalMinutes) {
        const minutes = totalMinutes % 60;
        const hours = Math.floor(totalMinutes / 60);

        return `${padTo2Digits(hours)}:${padTo2Digits(minutes)}`;
    }

    function padTo2Digits(num) {
        return num.toString().padStart(2, '0');
    }


    const onItemSelect = (itemId, e, time) => {
        const item = items.find((item) => item.id === itemId);
        let timeSlot = toHoursAndMinutes(item.content).toString();

        setSelectedItem(item);
        setTime(timeSlot);
        setForecast(item.previsionnel);
    }

    const onItemDeselect = (e) => {
        setSelectedItem(null)
    }

    const deleteItem = () => {
        setEdit(false);
        setOpen(false);
        if(selectedItem !== null){
            if(window.confirm("Are you sure to delete this forecast ?"))
                query_api_delete(selectedItem.obj['@id'])
                    .then((res) => {
                        setSelectedItem(null)
                    })
                    .catch((err)=> {
                        console.error(err)
                    })
        }
        else alert("There are no selected items on the timeline.")
    }


    const duplicateItem = () => {
        setOpen(true);
        setEdit(false);
    }

    const editItem = () => {
        setEdit(true);
        setOpen(false);
    }

    const handleClose = () => {
        setOpen(false);
        setEdit(false);
        setTimePerDay("01:00");
        setEndDate("");
        setStartDate("");
        setErrorMessage("");
    }

    const onPageChange = (id) => {
        setPageId({last: pageId.now, now: id})
        if(typeof timelineNode !== "undefined" && timelineNode !== null )
            timelineNode.scrollTo(0,0)
    }

    const generateVisibleGroups = () => {
        return props.groups
            .filter(line => line.type === "line" || openGroups.now.includes(line.lineID))
            .map(line => {
                return Object.assign({}, line, {
                    toggle: <FontAwesomeIcon icon={openGroups.now.includes(line.id)? faSortUp : faSortDown} className={"toggle-children"}/>,
                    onGroupClick: () => {
                        let _openGroups = Array.from(openGroups.now);
                        if(_openGroups.includes(line.id))
                            _openGroups = _openGroups.filter((value, index, arr) => {return value !== line.id});
                        else _openGroups.push(line.id);
                        setOpenGroups({last: openGroups.now, now: _openGroups})
                    }
                });
            });
    }


    const generateFilteredGroups = (visibleGroups) => {
        const _filteredGroups = [];
        const _filter = filter.now
        visibleGroups.filter((line) => line.type === "line")
            .forEach((line) => {

                if (line.name.toLowerCase().includes(_filter) || line.client.toLowerCase().includes(_filter)
                    || line.title.toLowerCase().includes(_filter) || line.tags.toLowerCase().includes(_filter)
                    || line.manager.toLowerCase().includes(_filter))
                {
                    _filteredGroups.push(line);

                    if(openGroups.now.includes(line.id))
                        visibleGroups.filter((_line) => _line.lineID === line.id)
                            .forEach((worker) => _filteredGroups.push(worker))
                }
            });
        return _filteredGroups;
    }


    const generateSlicedGroups = (filteredGroup) => {
        let startId = pageId.now * maxLineByPage
        let endId =  (pageId.now + 1) * maxLineByPage
        while(endId < filteredGroup.length && filteredGroup[endId].type === "underline") endId++

        return filteredGroup.slice(startId, endId)
    }

    const addDuplicateForecast = () => {
        let post_promises = [];

        if(validateTIME(timePerDay) && timePerDay !== "00:00"
            && validateDATE(startDate) && validateDATE(endDate)
            && startDate <= endDate
        ){
            setLoading(true);
            let duration = moment(endDate).endOf('day').businessDiff(moment(startDate).startOf('day'), 'days');

            const posted_data = {
                minutesPerDay: clockToMinutes(timePerDay, ":"),
                startDate: moment(startDate).format('YYYY-MM-DD'),
                endDate: moment(endDate).format('YYYY-MM-DD'),
                devis: selectedItem.obj.devis,
                employee: selectedItem.obj.employee,
                content: selectedItem.obj.content,
                creationDate: moment().format("YYYY-MM-DD HH:mm:ss"),
                dayDuration: (duration === 0)? 1 : duration,
                previsionnel: selectedItem.previsionnel
            }

            post_promises.push(query_api_post("/api/forecasts", posted_data));

            Promise.all(post_promises)
            .then((response) => {
                handleClose();
                setLoading(false);
            })
            .catch((error) => {
                console.error(error);
                setLoading(false);
                animErrorPost();
                setErrorMessage("Oops, an error occured. Please refresh the page.")
                setTimeout(()=>{
                    setErrorMessage("")
                }, 2000)
            })
        }
        else {
            animErrorForm();
            setErrorMessage("Please fill in the form.")
            if (startDate >= endDate) {
                setErrorMessage("End date cannot be greater than start date");
            }
            setTimeout(()=>{
                setErrorMessage("")
            }, 2000)
        }
    }

    const modifyForecast = () => {
        let post_promises = [];

        if(validateTIME(time) && time !== "00:00" ){
            setLoading(true);
            const posted_data = {
                ...selectedItem.obj,
                minutesPerDay: clockToMinutes(time, ":"),
                previsionnel: forecast
            }

            post_promises.push(query_api_put(selectedItem.obj['@id'], posted_data));

            Promise.all(post_promises)
            .then((response) => {
                handleClose();
                setLoading(false);
            })
            .catch((error) => {
                console.error(error);
                setLoading(false);
                animErrorPost();
                setErrorMessage("Oops, an error occured. Please refresh the page.")
                setTimeout(()=>{
                    setErrorMessage("")
                }, 2000)
            })
        }
        else {
            animErrorForm();
            setErrorMessage("Please fill in the form.")
            setTimeout(()=>{
                setErrorMessage("")
            }, 2000)
        }
    }

    return(
        <div id={"timeline-component"}>
            <div id={"timeline"}>
                <Timeline
                    groups={hydrateGroups()}
                    items={items}

                    visibleTimeStart={calendarTimeStart}
                    visibleTimeEnd={calendarTimeEnd}

                    onTimeChange={handleTimeChange}
                    onItemMove={onItemMove}
                    onItemResize={onItemResize}
                    onItemSelect={onItemSelect}
                    onItemDeselect={onItemDeselect}
                    canChangeGroup={false}
                    canMove={props.canEditItems}
                    canResize={props.canEditItems}
                    canSelect={props.canEditItems}

                    sidebarWidth={sideBarWidth}
                    lineHeight={50}
                    itemsSorted
                    showCursorLine
                    stackItems
                    minZoom={minZoom}
                    maxZoom={maxZoom}

                    itemRenderer={itemRenderer}
                    groupRenderer={groupRenderer}

                >
                    {CalendarHeaders({headers, filter}, handleFilterChange, handleHeaderClick)}

                </Timeline>
            </div>

            <footer id={"timeline-footer"}>
                <div id={"timeline-toolbar"}>
                    <div className={"section"}>NOTES</div>
                    <div className={"section notes"}>
                        {(selectedItem !== null)? selectedItem.obj.content + ((selectedItem.obj.previsionnel)? " (prévisionnel)" : "") : null}
                    </div>

                    <button className={(selectedItem === null)? "section disabled" : "section"} onClick={duplicateItem} disabled={selectedItem === null}
                            data-tip={"Duplicate the selected forecast on the timeline"} data-for={"button-duplicate-forecast"}>
                        <FontAwesomeIcon className={"icon"} icon={faClone}/>
                    </button>

                    <button className={(selectedItem === null)? "section disabled" : "section"} onClick={editItem} disabled={selectedItem === null}
                            data-tip={"Edit the selected forecast on the timeline"} data-for={"button-duplicate-forecast"}>
                        <FontAwesomeIcon className={"icon"} icon={faPen}/>
                    </button>

                    <button className={(selectedItem === null)? "section disabled" : "section"} onClick={deleteItem} disabled={selectedItem === null}
                            data-tip={"Delete the selected forecast on the timeline"} data-for={"button-delete-forecast"}>
                        <FontAwesomeIcon className={"icon"} icon={faTrash}/>
                    </button>

                    <button className={"section"} onClick={() => history.push("/add-forecast")}
                            data-tip={"Create a new forecast"} data-for={"button-create-forecast"}>
                        <FontAwesomeIcon className={"icon"} icon={faPlus}/>
                    </button>

                    <button id={"reset-timeline"} className={"section"} onClick={resetTimeline}
                            data-tip={"Center the timeline on the current week"} data-for={"button-reset-timeline"}>
                        <FontAwesomeIcon className={"icon"} icon={faSyncAlt}/>
                    </button>

                    <ReactTooltip id={"button-reset-timeline"} type={"dark"} place="left" effect="solid"/>
                    <ReactTooltip id={"button-delete-forecast"} type={"dark"} place="left" effect="solid"/>
                    <ReactTooltip id={"button-create-forecast"} type={"dark"} place="left" effect="solid"/>
                    <ReactTooltip id={"button-edit-forecast"} type={"dark"} place="left" effect="solid"/>
                    <ReactTooltip id={"button-duplicate-forecast"} type={"dark"} place="left" effect="solid"/>
                </div>

                <div className={"pagination"} style={{height: (pageCount > 1)? "40px" : 0, visibility: (pageCount > 1)? "visible" : "hidden"}}>

                    <div className={"allow"}>
                        <p>Use pagination </p>
                        <Switch onChange={() => setAllowPagination(!allowPagination)} checked={allowPagination}
                                handleDiameter={25}
                                onColor="#1dc3e7"
                                offColor="#37393A"
                                height={20}
                                width={45} />
                    </div>

                    {allowPagination &&
                        <ReactPaginate pageCount={pageCount} pageRangeDisplayed={3} marginPagesDisplayed={2}
                                       initialPage={pageId.now}
                                       forcePage={pageId.now}
                                       onPageChange={(page) => onPageChange(page.selected)}
                                       previousLabel={<FontAwesomeIcon icon={faChevronLeft}/>}
                                       nextLabel={<FontAwesomeIcon icon={faChevronRight}/>}
                                       containerClassName={"paginator"}/>
                    }
                </div>
            </footer>
            <DuplicateForecastModal
                show={open}
                handleClose={handleClose}
                startDate={startDate}
                endDate={endDate}
                endDateChange={(date) => setEndDate(date)}
                startDateChange={(date) => setStartDate(date)}
                timePerDay={timePerDay}
                timePerDayChange={(time) => setTimePerDay(time)}
                addForecast={addDuplicateForecast}
                errorMessage={errorMessage}
                loading={loading}
            />

            <EditForecastModal
                show={edit}
                forecast={selectedItem}
                handleClose={handleClose}
                timePerDay={time}
                timePerDayChange={(time) => setTime(time)}
                forecastChange={(forecast) => setForecast(forecast)}
                previsionnel = {forecast}
                modifyForecast={modifyForecast}
                loading={loading}
                errorMessage={errorMessage}
            />
        </div>
    )
}


export default Calendar
