import * as moment from "moment";


export function generateProjectsTimelineData(rawApiData, isTimesheet){

    const lines = [], underlines = [], items = []
    const object = (isTimesheet)? "reports" : "forecasts";

    let client = null;
    let team  = null;
    let project  = null;
    let responsable = null;
    let line = {};

    const createLine = (object) => {
        const devis = rawApiData.devis.find((devis) => devis['@id'] === object.devis);
        if (devis) {
            project = rawApiData.projects.find((project) => project['@id'] === devis.project);
            team = rawApiData.teams.find((team) => team['@id'] === devis.team);
            if (project) {
                if (team) {
                    client = rawApiData.clients.find((client) => client['@id'] === project.client);
                    responsable = rawApiData.employees.find((employee) => employee['@id'] === team.responsable);

                    line = {
                        id: `line%${devis.id}`,
                        type: "line",
                        name: project.name,
                        client: client.name,
                        tags: "",
                        title: devis.tag,
                        color: team.color,
                        manager: (typeof responsable !== "undefined") ? responsable.firstName + " " + responsable.lastName : "",
                        teamId: team['@id']
                    }
                    lines.push(line);
                }
            }
        }
        return line;
    }

    const createUnderline = (object, line) => {
        const employee = rawApiData.employees.find((employee) => employee['@id'] === object.employee)
        if (employee) {
            const title = employee.firstName + " " + employee.lastName
            const team_composition = rawApiData.team_compositions.find((team_comp) =>
                (team_comp.team === line.teamId && team_comp.employee === object.employee))

            const underline = {
                id: `underline%${line.id}%${employee.id}`,
                type: "underline",
                redirectTo: (isTimesheet) ? "/timeteam?filter=" + encodeURIComponent(title.toLowerCase()) : "/teams?filter=" + encodeURIComponent(title.toLowerCase()),
                lineID: line.id,
                title: title,
                tags: (team_composition === undefined) ? "Undefined" : team_composition.status,
                color: line.color
            };

            underlines.push(underline)
            return underline
        }
    }

    rawApiData[object].forEach((object) => {
        if((isTimesheet)? object.isSent : moment(object.endDate).isAfter(moment().subtract(2, 'week'))) {

            if (object && object.devis) {
                let line = lines.find((line) => line.id === `line%${object.devis.split('/')[3]}`)
                if (line === undefined) line = createLine(object)

                let underline = underlines.find((underline) => underline.id === `underline%${line.id}%${object.employee.split('/')[3]}`)
                if (underline === undefined) underline = createUnderline(object, line)

                if (underline) {
                    const item = (isTimesheet) ? {
                        id: "item%" + object.id,
                        group: underline.id,
                        color: underline.color,
                        content: object.minutesPerDay,
                        start_time: moment(object.creationDate.split("T")[0]).startOf('day'),
                        end_time: moment(object.creationDate.split("T")[0]).endOf('day'),
                        obj: object,
                        isGroupItem: false
                    } : {
                        id: "item%" + object.id,
                        group: underline.id,
                        color: underline.color,
                        content: object.minutesPerDay,
                        start_time: moment(object.startDate).startOf('day'),
                        end_time: moment(object.endDate).endOf('day'),
                        obj: object,
                        isGroupItem: false,
                        previsionnel: object.previsionnel
                    }

                    const item_split = weekendItemSplitter(item);
                    items.push(...item_split)
                }
            }
        }
    })

    return {
        groups : [
            ...lines.sort((a, b) => a.name.localeCompare(b.name)),
            ...underlines.sort((a, b) => a.title.localeCompare(b.title)),
        ],
        items: [...items]
    }
}


export function generateTeamsTimelineData(rawApiData, isTimesheet){

    let client = null
    let underline = {};
    let devis_objects = {};
    let project = {};

    const lines = [], underlines = [], items = []
    const object = (isTimesheet)? "reports" : "forecasts";

    rawApiData.teams.forEach((team) => {
        if (team) {
        const responsable = rawApiData.employees.find((employee) => employee['@id'] === team.responsable)
        team.compositions.forEach((composition_id) => {
            const composition = rawApiData.team_compositions.find((composition) => composition['@id'] === composition_id)
            if(composition !== undefined){

                const employee = rawApiData.employees.find((employee) => employee['@id'] === composition.employee)
                const employee_objects = rawApiData[object].filter((obj) => obj.employee === employee['@id'])

                if(employee.enabled || (!employee.enabled && employee_objects.length > 0)){
                    const line = {
                        id: `line%${employee.id}%${team.id}`,
                        type: "line",
                        name: team.name,
                        client: composition.status,
                        title: employee.firstName + " " + employee.lastName,
                        tags: "",
                        color: team.color,
                        manager: (typeof responsable !== "undefined") ? responsable.firstName + " " + responsable.lastName : "",
                        team_type: team.type
                    }
                    lines.push(line)

                    let devis_id_list = team.devis

                    if(team.type !== "TEAM_PROJECT" && devis_id_list){
                        devis_id_list = []
                        employee_objects.map((_object) => {
                            if(!devis_id_list.includes(_object.devis))
                                devis_id_list.push(_object.devis)
                        })
                    }


                    devis_id_list.forEach((devis_id) => {
                        const devis = rawApiData.devis.find((devis) => devis['@id'] === devis_id)
                        if (devis) {
                             devis_objects = rawApiData[object].filter((obj) => obj.devis === devis['@id'])
                             project = rawApiData.projects.find((project) => project['@id'] === devis.project);

                            if (project) {
                                client = rawApiData.clients.find((client) => client['@id'] === project.client)

                                underline = {
                                    id: `underline%${employee.id}%${team.id}%${devis.id}`,
                                    type: "underline",
                                    lineID: line.id,
                                    name: project.name,
                                    client: client.name,
                                    title: devis.tag,
                                    tags: project.name,
                                    redirectTo: (isTimesheet) ? "/timeproj?filter=" + encodeURIComponent(devis.tag.toLowerCase()) : "/?filter=" + encodeURIComponent(devis.tag.toLowerCase()),
                                    color: team.color
                                }
                            }
                        }

                        underlines.push(underline)

                        if (Object.keys(devis_objects).length > 0) {
                            devis_objects.forEach((devis_object) =>
                                employee_objects.filter((employee_object) => employee_object.id === devis_object.id).forEach((valid_object) => {

                                    if((isTimesheet)? valid_object.isSent : moment(valid_object.endDate).isAfter(moment().subtract(2, 'week'))){

                                        const item = (isTimesheet)? {
                                            id: `item%${valid_object.id}%${team.id}`,
                                            group: underline.id,
                                            color: team.color,
                                            content: valid_object.minutesPerDay,
                                            start_time: moment(valid_object.creationDate.split("T")[0]).startOf('day'),
                                            end_time: moment(valid_object.creationDate.split("T")[0]).endOf('day'),
                                            obj: valid_object,
                                            isGroupItem: false
                                        } : {
                                            id: `item%${valid_object.id}%${team.id}`,
                                            group: underline.id,
                                            color: team.color,
                                            content: valid_object.minutesPerDay,
                                            start_time: moment(valid_object.startDate).startOf('day'),
                                            end_time: moment(valid_object.endDate).endOf('day'),
                                            obj: valid_object,
                                            isGroupItem: false,
                                            previsionnel: valid_object.previsionnel
                                        }

                                        const item_split = weekendItemSplitter(item);
                                        items.push(...item_split)
                                    }
                                })
                            )
                        }
                    })
                }
            }
        }) }
    })

    const underLines = underlines.filter(obj => obj.hasOwnProperty("title") && obj.title)

    return {
        groups : [
            ...lines.sort((a, b) => a.name.localeCompare(b.name)),
            ...underLines.sort((a, b) => a.title.localeCompare(b.title)),
        ],
        items: [...items]
    }
}


export function generateUsersTimelineData(rawApiData , isTimesheet){

    const lines = [], underlines = [], items = []
    const object = (isTimesheet)? "reports" : "forecasts";

    // Create all lignes for each existing employee
    rawApiData.employees.forEach((employee) => {
        if(employee.enabled)
            lines.push({
                id: `line%${employee.id}`,
                type: "line",
                name: employee.tags.join(' | '),
                client: "",
                title: employee.firstName + " " + employee.lastName,
                tags: "",
                color: "#384A5D",
                manager: "",
            })
    })

    const createUnderline = (object) => {
        let client = null;
        let team = null;
        let project = null;
        let underline = {};
        const devis = rawApiData.devis.find((devis) => devis['@id'] === object.devis)
        if (devis) {
            team = rawApiData.teams.find((team) => team['@id'] === devis.team)
            project = rawApiData.projects.find((project) => project['@id'] === devis.project)
            if (project) {
                if (team) {
                    client = rawApiData.clients.find((client) => client['@id'] === project.client)
                    underline = {
                        id: `underline%${object.employee.split('/')[3]}%${devis.id}`,
                        type: "underline",
                        redirectTo: (isTimesheet) ? "/timeproj?filter=" + encodeURIComponent(devis.tag.toLowerCase()) : "/?filter=" + encodeURIComponent(devis.tag.toLowerCase()),
                        lineID: `line%${object.employee.split('/')[3]}`,
                        title: devis.tag,
                        tags: project.name + " | " + client.name,
                        color: team.color,
                        data: {
                            team: team
                        }
                    }
                }
            }
        }
        underlines.push(underline)
        return underline
    }

    rawApiData[object].forEach((object) => {
        let underline = underlines.find((underline) => underline.id === `underline%${object.employee.split('/')[3]}%${object.devis.split('/')[3]}`)
        if(underline === undefined ) underline = createUnderline(object)
        let start = !isTimesheet ? moment(object.startDate).startOf('day') : moment(object.creationDate.split("T")[0]).startOf('day'), end = !isTimesheet ? moment(object.endDate).endOf('day') : moment(object.creationDate.split("T")[0]).endOf('day');
        if (Object.keys(underline).length !== 0) {
            const item = {
                id: `item%${object.id}`,
                group: underline.id,
                color: underline.data.team.color,
                content: object.minutesPerDay,
                start_time:start,
                end_time: end,
                obj: object,
                isGroupItem: false,
                previsionnel: !isTimesheet ? object.previsionnel : !object.isSent
            }
            const item_split = weekendItemSplitter(item);
            items.push(...item_split)
        }
    });

    const underLines = underlines.filter(obj => obj.hasOwnProperty("title") && obj.title)
    return {
        groups : [
            ...lines.sort((a, b) => a.title.localeCompare(b.title)),
            ...underLines.sort((a, b) => a.title.localeCompare(b.title)),
        ],
        items: [...items]
    }
}


export function addGroupsItems(groups, items) {

    let lines = groups.filter((group) => group.type === "line");
    let underlines = groups.filter((group) => group.type === "underline");
    let newItems = Array.from(items);

    lines.forEach((line) => {
        let groupUnderlines = underlines.filter((underline) => underline.lineID === line.id);

        if(groupUnderlines.length >= 1) {
            let lineItem = {}, itemsProjectEndTime = [], itemsProjectStartTime = [];

            groupUnderlines.forEach((groupUnderline) => {
                items.filter((item) => item.group === groupUnderline.id)
                    .forEach((item) => {
                        itemsProjectEndTime.push(item.end_time);
                        itemsProjectStartTime.push(item.start_time)
                    });
            });

            if(itemsProjectStartTime.length > 0) {
                lineItem.id = newItems.length + 1;
                lineItem.group = line.id;
                lineItem.color = line.color;
                lineItem.start_time = moment.min(itemsProjectStartTime);
                lineItem.end_time = moment.max(itemsProjectEndTime);
                lineItem.isGroupItem = true;
                lineItem.canMove = false;
                lineItem.canResize = false;
                lineItem.canChangeGroup = false;
                lineItem.canSelect = false;

                newItems.push(lineItem);
            }
        }
    });
    return newItems;
}


function weekendItemSplitter(item) {

    let items = [];
    let start = moment(item.start_time), end = moment(item.end_time);

    if(start.day() === 0) item.start_time = start.add(1, 'day').startOf('day');
    else if(start.day() === 6) item.start_time = start.add(2, 'day').startOf('day');

    if(end.day() === 0) item.end_time = end.subtract(2, 'day').endOf('day');
    else if(end.day() === 6) item.end_time = end.subtract(1, 'day').endOf('day');

    if(start.isAfter(end)) return items;

    else if(start.week() !== end.week()){

        let now = moment(start), splitItemStart = moment(start);
        let countLoop = 0
        do{

            if(splitItemStart === null && now.day() === 1)
                splitItemStart = moment(now);

            if(now.day() === 5){
                let splitItem = {...item};
                splitItem.id = item.id + "%split" + countLoop;
                splitItem.start_time = moment(splitItemStart.startOf('day'));
                splitItem.end_time = moment(now.endOf('day'));

                splitItemStart = null;
                items.push(splitItem);
            }

            now.add(1, 'day');
            countLoop++

        }while(now.isSameOrBefore(end));

        if(splitItemStart !== null){
            let splitItem = {...item};
            splitItem.id =  item.id + "%split" + countLoop;
            splitItem.start_time = splitItemStart.startOf('day');
            splitItem.end_time = end.endOf('day');

            items.push(splitItem);
        }
    }
    else items.push(item);

    return items;
}

